import request from '@/utils/index'

export const queryAlgorithmList = () => {
    return request.get(`/admin/algorithm`)
}
export const queryAlgorithmDetail = (app_id: string) => {
    return request.get(`/admin/algorithm/${app_id}`)
}
export const uploadAlgorithm = (data: any, algo_id: string) => {
    if (!algo_id) {
        return request.post(`/admin/algorithm`, data)
    } else {
        return request.post(`/admin/algorithm/${algo_id}/update`, data)
    }
}
