

































import { Component, Mixins } from 'vue-property-decorator'
import Pagination from '@/components/pagination.vue'
import { confirm } from '@/utils/decorator'
import PaginationToQuery from '@/mixins/pagination-to-query'
import { IApps } from '@/models'
import { queryAppsList } from '@/api/specimen'
import { deleteSpecimen, exportExcel, importExcelPost, importImagesPost } from '@/api/admin'
import { downloadFile } from '@/utils/common'
import { readFile } from '@/utils/readfile'

@Component({ components: { Pagination } })
export default class SpecimenManagement extends Mixins<any>(PaginationToQuery) {
  pageInfo = {
    page: 1,
    num: 10,
    all_page: 0
  }

  private searchForm: any = {}

  private uploadLoading = false
  private imageUploadLoading = false
  private downloadLoading = false
  private textButtonLoading = false

  private appsLists: IApps[] = []

  private mounted() {
    this.query()
  }

  private async query() {
    try {
      ;(this as any).loading = true

      const { data, all_page } = await queryAppsList()

      this.appsLists = [...data]
      for (let i = 0; i < this.appsLists.length; i++) {
        if (this.appsLists[i].state) {
          this.appsLists[i].state = '正常'
        } else {
          this.appsLists[i].state = '已删除'
        }
      }
      this.pageInfo.all_page = all_page
    } catch (e) {
      console.warn(e)
    } finally {
      ;(this as any).loading = false
    }
  }

  private doImageUpload(file: any) {
    const callback = async (data: any) => {
      try {
        this.imageUploadLoading = true
        await importImagesPost(data)
        this.$message.success(`导入成功`)
      } catch (e) {
        console.log(e)
      } finally {
        this.imageUploadLoading = false
      }
    }
    readFile(file.raw, callback)
  }

  private doUpload(file: any) {
    const callback = async (data: any) => {
      try {
        this.uploadLoading = true
        const { success_num, fail_num, url } = await importExcelPost(data)
        this.$message.success(`导入结果：成功${success_num}条,失败${fail_num}条`)
        if (url) {
          downloadFile(url)
        }
        this.query()
      } catch (e) {
        console.log(e)
      } finally {
        this.uploadLoading = false
      }
    }
    readFile(file.raw, callback)
  }

  private handleFileChange(file: any) {
    const fileExt = file.name.replace(/.+\./, '')
    if (['xlsx', 'xls'].indexOf(fileExt.toLowerCase()) === -1) {
      this.$message.error('格式不符合要求，请上传.xlsx、.xls格式的文件')
      return
    }
    this.doUpload(file)
  }

  private handleImageFileChange(file: any) {
    const fileExt = file.name.replace(/.+\./, '')
    if (['zip'].indexOf(fileExt.toLowerCase()) === -1) {
      this.$message.error('格式不符合要求，请上传.zip格式的文件')
      return
    }
    this.doImageUpload(file)
  }

  private async handleDownload() {
    try {
      this.downloadLoading = true
      const params = {
        page: this.pageInfo.page,
        num: this.pageInfo.num,
        ...this.searchForm
      }
      const { url } = await exportExcel(params)
      console.log(url, 'url')
      downloadFile(url)
      this.$message.success('下载成功')
    } catch (e) {
      console.warn(e, 'handleDownload')
    } finally {
      this.downloadLoading = false
    }
  }

  private handleQuery() {
    this.pageInfo.page = 1
    this.query()
  }

  private handleReset() {
    this.searchForm = {}
    this.handleQuery()
  }

  private handleAdd() {
    this.$router.push(`/apps-management/ud-detail?metaTitle=新增应用`)
  }

  private handleView({ app_id }: any) {
    this.$router.push(
        `/specimen-management/detail?metaTitle=标本详情&specimen_number=${app_id}`
    )
  }

  private handleEdit({ app_id }: any) {
    console.log('编辑   ', app_id)
    this.$router.push(
        `/apps-management/ud-detail?metaTitle=编辑应用&app_id=${app_id}`
    )
  }

  @confirm('确认要删除此应用吗？')
  private async handleDelete({ app_id }: any) {
    try {
      this.textButtonLoading = true
      await deleteSpecimen(app_id)
      this.$message.success('删除成功')
      this.handleQuery()
    } catch (e) {
      console.log(e, 'handleDelete')
    } finally {
      this.textButtonLoading = false
    }
  }
}
