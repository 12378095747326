















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
@Component
export default class Pagination extends Vue {
  @Prop({ default: 1 }) currentPage!: number
  @Prop({ default: 5 }) pageSize!: number
  @Prop({ default: 0 }) pageCount: number = 0

  @Emit('sizeChange')
  private handleSizeChange(val: number) {
    console.log(`每页 ${val} 条`)
  }
  @Emit('currentChange')
  private handleCurrentChange(val: number) {
    console.log(`当前页: ${val}`)
  }
}
