













































import { Component, Mixins } from 'vue-property-decorator'
import Pagination from '@/components/pagination.vue'
import { confirm } from '@/utils/decorator'
import PaginationToQuery from '@/mixins/pagination-to-query'
import { IAlgorithm } from '@/models'
import { queryAlgorithmList } from '@/api/algorithm'
import { deleteSpecimen, exportExcel, importExcelPost, importImagesPost } from '@/api/admin'
import { downloadFile } from '@/utils/common'
import { readFile } from '@/utils/readfile'

function exportFile(file:Uint8Array, name: string) {
  const url = window.URL.createObjectURL(new Blob([file]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link) // 下载完成移除元素
  window.URL.revokeObjectURL(url) // 释放掉blob对象
}

@Component({ components: { Pagination } })
export default class AlgorithmManagement extends Mixins<any>(PaginationToQuery) {
  pageInfo = {
    page: 1,
    num: 10,
    all_page: 0
  }

  private searchForm: any = {}

  private uploadLoading = false
  private imageUploadLoading = false
  private downloadLoading = false
  private textButtonLoading = false

  private algorithmLists: IAlgorithm[] = []

  private mounted() {
    this.query()
  }

  private downloadParams(item: { params: any; algo_name: string }, algorithm_name: string) {
    const params = item.params
    const imageContent = atob(params)
    const buffer = new ArrayBuffer(imageContent.length)
    const params_bytes = new Uint8Array(buffer)
    for (var i = 0; i < imageContent.length; ++i) {
      params_bytes[i] = imageContent.charCodeAt(i)
    }

    exportFile(params_bytes, item.algo_name + '.dat')
  }

  private async query() {
    try {
      ;(this as any).loading = true

      const { data } = await queryAlgorithmList()

      this.algorithmLists = [...data]
      for (let i = 0; i < this.algorithmLists.length; i++) {
        this.algorithmLists[i].algo_id = this.algorithmLists[i].id
        if (this.algorithmLists[i].state) {
          this.algorithmLists[i].state = '正常'
        } else {
          this.algorithmLists[i].state = '已删除'
        }
      }
    } catch (e) {
      console.warn(e)
    } finally {
      ;(this as any).loading = false
    }
  }

  private doImageUpload(file: any) {
    const callback = async (data: any) => {
      try {
        this.imageUploadLoading = true
        await importImagesPost(data)
        this.$message.success(`导入成功`)
      } catch (e) {
        console.log(e)
      } finally {
        this.imageUploadLoading = false
      }
    }
    readFile(file.raw, callback)
  }

  private doUpload(file: any) {
    const callback = async (data: any) => {
      try {
        this.uploadLoading = true
        const { success_num, fail_num, url } = await importExcelPost(data)
        this.$message.success(`导入结果：成功${success_num}条,失败${fail_num}条`)
        if (url) {
          downloadFile(url)
        }
        this.query()
      } catch (e) {
        console.log(e)
      } finally {
        this.uploadLoading = false
      }
    }
    readFile(file.raw, callback)
  }
  private async handleDownload() {
    try {
      this.downloadLoading = true
      const params = {
        page: this.pageInfo.page,
        num: this.pageInfo.num,
        ...this.searchForm
      }
      const { url } = await exportExcel(params)
      console.log(url, 'url')
      downloadFile(url)
      this.$message.success('下载成功')
    } catch (e) {
      console.warn(e, 'handleDownload')
    } finally {
      this.downloadLoading = false
    }
  }

  private handleQuery() {
    this.pageInfo.page = 1
    this.query()
  }

  private handleReset() {
    this.searchForm = {}
    this.handleQuery()
  }

  private handleAdd() {
    this.$router.push(`/algorithm-management/ud-detail?metaTitle=新增算法`)
  }

  private handleEdit({ algo_id }: any) {
    console.log('---------- algo_id', algo_id)
    this.$router.push(
        `/algorithm-management/ud-detail?metaTitle=编辑算法&algo_id=${algo_id}`
    )
  }
  @confirm('确认要删除此应用吗？')
  private async handleDelete({ app_id }: any) {
    try {
      this.textButtonLoading = true
      await deleteSpecimen(app_id)
      this.$message.success('删除成功')
      this.handleQuery()
    } catch (e) {
      console.log(e, 'handleDelete')
    } finally {
      this.textButtonLoading = false
    }
  }
}
